import {
  Container,
  ContentInfos,
  Image,
  Info,
  Location,
  Name,
  Price,
} from "./styles";

const Card = ({
  image,
  name,
  location,
  price,
  squareMeters,
  parkingSpaces,
  dormitory,
  onClick,
  suite,
  card,
}) => {
  return (
    <Container onClick={onClick}>
      <Image
        alt="imovel"
        style={{
          backgroundImage: `url("${image}")`,
          borderBottom: card === "default" ? "" : "5px solid #FF4900",
        }}
      />

      <ContentInfos>
        <Name
          style={{
            color: card === "default" ? "" : "#FF4900",
            fontSize: card === "default" ? 22 : 25,
          }}
        >
          {name}
        </Name>
        <Location>{location}</Location>

        <Info>{squareMeters} m²</Info>

        {parkingSpaces !== 0 && <Info>{parkingSpaces} vaga(s)</Info>}
        {suite !== 0 && <Info>{suite} suíte(s)</Info>}
        {dormitory !== 0 && <Info>{dormitory} dormitório(s)</Info>}
        <br />

        {price !== 0.1 && (
          <>
            <span style={{ fontSize: 13 }}>A partir de</span>

            <Price style={{ color: card === "default" ? "" : "#FF4900" }}>
              {price.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })}
            </Price>
          </>
        )}
      </ContentInfos>
    </Container>
  );
};

export default Card;
