import Banner from "../../components/banner";
import Footer from "../../components/footer";
import Header from "../../components/header";
import Highlights from "../../components/highlights";
import HousingBenefits from "../../components/housingBenefits";

const Home = () => {
  return (
    <div>
      <Header fixed={true} />
      <Banner />
      <HousingBenefits />
      <Highlights />
      <Highlights highlights={false} />
      <Footer />
    </div>
  );
};

export default Home;
