import React from "react";
import { useNavigate } from "react-router-dom";

import SidebarItem from "./sidebarItem";

import { FaTimes } from "react-icons/fa";

import { Container, Content, Navigation, Info } from "./styles";

const SideBar = ({ visible, setVisible }) => {
  const navigate = useNavigate();

  const closeSideBar = () => {
    setVisible(false);
  };

  const handleNavLink = (event) => {
    event.preventDefault();
    const sectionId = event.target.getAttribute("href");
    const section = document.querySelector(sectionId);

    if (section) {
      closeSideBar();
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleNavigate = (path) => {
    closeSideBar();

    setTimeout(() => {
      navigate(path);
    }, 300);
  };

  return (
    <Container sidebar={visible ? true : false}>
      <FaTimes color="#FF4900" onClick={closeSideBar} />
      <Content>
        <Navigation>
          <SidebarItem>LANÇAMENTOS</SidebarItem>
        </Navigation>
        <Navigation>
          <SidebarItem onClick={() => handleNavigate("/imoveis/obras")}>
            OBRAS
          </SidebarItem>
        </Navigation>
        <Navigation>
          <SidebarItem
            onClick={() => handleNavigate("/imoveis/pronto-para-morar")}
          >
            PRONTO PARA MORAR
          </SidebarItem>
        </Navigation>
      </Content>

      <Info>
        <Navigation>
          <SidebarItem onClick={() => handleNavigate("/sobre-nos")}>
            Sobre Nós
          </SidebarItem>
        </Navigation>
        <Navigation>
          <SidebarItem href="#footer" onClick={(event) => handleNavLink(event)}>
            Contato
          </SidebarItem>
        </Navigation>
      </Info>
    </Container>
  );
};

export default SideBar;
