import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./home";
import Chat from "../components/chat";
import PrivacyPolicy from "./privacyPolicy";
import AboutUs from "./aboutUs";
import RenderCategory from "./renderCategory";
import ScrollToTop from "../components/scrollToTop";
import RenderInformations from "./renderInformations";
import RenderSearchResults from "./renderSearchResults";

const Root = () => {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/chat" element={<Chat />} />
        <Route path="/politica-de-privacidade" element={<PrivacyPolicy />} />
        <Route path="/sobre-nos" element={<AboutUs />} />
        <Route path="/imoveis/:name" element={<RenderCategory />} />
        <Route path="/imovel/:id" element={<RenderInformations />} />
        <Route path="/buscar-imoveis" element={<RenderSearchResults />} />
      </Routes>
    </Router>
  );
};

export default Root;
