import React from "react";
import styled from "styled-components";

const BorderContainer = styled.div`
  position: absolute;
  width: 100%;
`;

const ColorBorder = styled.div`
  height: 4px;
  background: linear-gradient(
    to left,
    #ed3f1a 0%,
    #ff3214 34%,
    #28711f 65%,
    #77b011 100%
  );
`;

const Border = ({ children, bottom, top }) => {
  return (
    <BorderContainer style={{ bottom: bottom, top: top }}>
      <ColorBorder />
      {children}
    </BorderContainer>
  );
};

export default Border;
