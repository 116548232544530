import styled from "styled-components";

export const Container = styled.div`
  max-width: 360px;
  width: 100%;

  display: flex;
  flex-direction: column;
  background-color: white;
  cursor: pointer;
  border-radius: 15px;
  border: 1px solid #cfcfcf;
  padding-bottom: 20px;
`;

export const ContentInfos = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 18px;
  color: #3c3c3b;
`;

export const Image = styled.div`
  height: 200px;
  margin-bottom: 16px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-bottom: 5px solid #28711f;
  border-radius: 15px;
`;

export const Name = styled.span`
  color: #28711f;
  font-weight: bold;
  margin-bottom: 5px;
`;

export const Location = styled.span`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 16px;
`;

export const Info = styled.span`
  font-size: 14px;
`;

export const Price = styled.span`
  color: #28711f;
  font-size: 20px;
  font-weight: bold;
`;
