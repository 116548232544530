import styled from "styled-components";

export const Container = styled.section`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 3;
  align-items: center;
  padding: 20px 20px;
  background-color: white;
  overflow-y: auto;
  top: 0;
  left: 0;
  bottom: 0;

  .rcb-chat-window {
    width: 100%;
    max-width: 687px;
  }
`;

export const Content = styled.div`
  max-width: 1128px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  position: relative;

  img {
    width: auto;
  }

  h3 {
    color: #ff4900;
    font-size: 16px;
    cursor: pointer;

    @media (max-width: 768px) {
      display: none;
    }
  }

  div {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
  }
`;

export const Title = styled.h1`
  color: #ff4900;
  font-size: 39px;
`;

export const RenderChat = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    justify-content: center;
  }

  figure {
    img {
      width: 100%;
    }

    @media (max-width: 768px) {
      display: none;
    }
  }
`;

export const Icon = styled.img``;

export const Data = styled.div`
  color: #3c3c3b;
  margin-top: 10px;
  margin-left: 50px;
  text-align: start;
`;

export const RenderCards = styled.div`
  padding: 30px 50px;
`;
