import { useState } from "react";
import { useNavigate } from "react-router-dom";

import Button from "../button";
import Border from "../border";
import Chat from "../chat";
import Location from "../location";

import {
  Container,
  ContentText,
  Image,
  ContainerBanner,
  ContainerImage,
  Search,
  ContainerButtons,
  Title,
} from "./styles";

import ImageBanner from "../../assets/image.webp";

const Banner = () => {
  const navigate = useNavigate();
  const [isModalOpen, setModalOpen] = useState(false);

  const [address, setAddress] = useState({
    neighborhood: "",
    city: "",
    state: "",
  });

  const toggleModal = () => setModalOpen(!isModalOpen);

  const handleSearch = () => {
    const { neighborhood, city, state } = address;
    navigate(
      `/buscar-imoveis?area=${neighborhood}&city=${city}&state=${state}`
    );
  };

  const handleAddressChange = (details) => {
    setAddress(details);
  };

  return (
    <Container>
      <Border bottom={0} />
      <ContainerBanner>
        <ContentText>
          <Title>
            Estamos prontos pra realizar o sonho da compra do seu imóvel!
          </Title>

          <Search>
            <label>Encontre seu novo lar aqui</label>
            <Location onChange={handleAddressChange} />
            <ContainerButtons>
              <Button
                icon="search"
                backgroundColor="#FF4900"
                button=""
                onClick={handleSearch}
              >
                BUSCAR IMÓVEIS
              </Button>
              <Button
                icon="dog"
                backgroundColor="#77B011"
                onClick={toggleModal}
                button=""
              >
                FALAR COM IA
              </Button>
              {isModalOpen && <Chat onClose={toggleModal} />}
            </ContainerButtons>
          </Search>
        </ContentText>

        <ContainerImage>
          <Image src={ImageBanner} alt="imagem" />
        </ContainerImage>
      </ContainerBanner>
    </Container>
  );
};

export default Banner;
