import { useState } from "react";
import ChatBot from "react-chatbotify";
import InputValidator from "@rcb-plugins/input-validator";

import Title from "../title";
import api from "../../api";
import axios from "axios";
import Card from "../card";

import {
  Container,
  Content,
  Header,
  RenderChat,
  Icon,
  Data,
  RenderCards,
} from "./styles";

import Logo from "../../assets/logo.svg";
import Dog from "../../assets/dog.jpg";
import Close from "../../assets/icons/close.svg";
import IconDog from "../../assets/icons/urbinho.png";
import IconUrblar from "../../assets/icons/urblar.svg";

const Chat = ({ onClose }) => {
  const [form, setForm] = useState({});
  const [name, setName] = useState();
  const [cities, setCities] = useState();

  const [properties, setProperties] = useState([]);
  const [neighborhood, setNeighborhood] = useState([]);

  const plugins = [InputValidator()];

  const recaptcha = async () => {
    return new Promise((resolve, reject) => {
      if (!window.grecaptcha) {
        return reject(new Error("grecaptcha is not loaded"));
      }

      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute("6LeOXGMqAAAAAOEq2cllcTFEtbY7m0TKnRT8poQd", {
            action: "submit",
          })
          .then(resolve)
          .catch(reject);
      });
    });
  };

  const handleProperty = async () => {
    const data = {
      state: form.state,
      area: form.neighborhood.split(", "),
      status:
        form.propertyStatus === "Tanto faz"
          ? ["under_construction", "ready"]
          : form.propertyStatus === "Em construção"
          ? "under_construction"
          : "ready",

      type: [form.propertyType === "Apartamento" ? "apartments" : "house"],
      bedrooms: [
        form.numberBedrooms === "Indiferente" ? "1+" : form.numberBedrooms,
      ],
      city: form.city,
      max_price: 350000,
    };

    try {
      const response = await api.get("/buildings", {
        params: data,
      });

      const properties = response.data.buildings || [];

      setProperties(properties);

      if (properties.length > 0) {
        return true;
      }

      return false;
    } catch (error) {
      console.log("Erro: ", error);
      return false;
    }
  };

  const onSubmit = async () => {
    try {
      const token = await recaptcha();

      axios.post("https://api.ymk.com.br/register", {
        ...form,
        name: name,
        recaptchaToken: token,
        chat: "Urblar",
        id_produto: 21,
        midia: "Urblar",
        link: properties.map(
          (item) => `https://urblar.com.br/imovel/${item.id}`
        ),
      });

      if (form.state !== "SP" || properties.length === 0) {
        setTimeout(() => {
          window.open("https://wa.me/5511957860221");
        }, 1000);
      }
    } catch (error) {
      console.log("Erro: ", error);
    }
  };

  const fetchNeighborhood = async (city) => {
    setForm({ ...form, city: city });

    try {
      const response = await api.get("/addresses/areas", {
        params: {
          state: "SP",
          city: city,
        },
      });

      setNeighborhood(response.data.areas);

      return true;
    } catch (error) {
      console.log("Erro: ", error);
      return false;
    }
  };

  const flow = {
    start: {
      message:
        "Olá! Tudo bem? Começa aqui nossa jornada para realizar o sonho da compra de seu imóvel!",
      path: "name",
      transition: { duration: 1000 },
    },

    name: {
      message: "Para começar, poderia me fornecer seu nome, por favor?",
      path: "states",
      function: (params) => setName(params.userInput),
    },

    states: {
      message: (params) =>
        `Ótimo, ${params.userInput}! Em qual estado você quer comprar seu novo imóvel?`,

      options: ["SP", "RJ", "MG", "ES", "Outro"],
      chatDisabled: true,
      function: async (params) => {
        if (params.userInput === "Outro") {
          params.goToPath("listStates");
        } else if (params.userInput !== "SP") {
          params.goToPath("phone");
        } else {
          setForm((form) => ({ ...form, state: params.userInput }));

          try {
            const response = await api.get("/addresses/cities", {
              params: {
                state: "SP",
              },
            });

            const orderCities = response.data.cities.sort((a, b) => {
              if (a === "São Paulo" || a === "Campinas") return -1;
              if (b === "São Paulo" || b === "Campinas") return 1;
              return 0;
            });

            setCities(orderCities);
            params.goToPath("city");
          } catch (error) {
            console.log(error);
          }
        }
      },
    },

    listStates: {
      message: "Escolha o estado",
      options: [
        "AC",
        "AL",
        "AP",
        "AM",
        "BA",
        "CE",
        "DF",
        "GO",
        "MA",
        "MT",
        "MS",
        "PA",
        "PB",
        "PR",
        "PE",
        "PI",
        "RN",
        "RS",
        "RO",
        "RR",
        "SC",
        "SE",
        "TO",
      ],
      chatDisabled: true,
      function: (params) => {
        setForm((form) => ({ ...form, state: params.userInput }));
      },
      path: "phone",
    },

    city: {
      message: "Escolha a cidade",
      options: [...(cities?.slice(0, 5) || []), "Outra"],
      chatDisabled: true,
      function: async (params) => {
        if (params.userInput === "Outra") {
          params.goToPath("otherCities");
        } else {
          const response = await fetchNeighborhood(params.userInput);

          if (response) {
            params.goToPath("neighborhood");
          }
        }
      },
    },

    otherCities: {
      message: "Escolha a cidade",
      options: cities?.slice(6),
      function: async (params) => {
        const response = await fetchNeighborhood(params.userInput);

        if (response) {
          params.goToPath("neighborhood");
        }
      },
    },

    neighborhood: {
      message: `Escolha o bairro em ${form.city}`,
      checkboxes: { items: neighborhood?.slice(10), min: 1 },
      chatDisabled: true,
      path: "propertyType",
      function: (params) => {
        setForm((form) => ({ ...form, neighborhood: params.userInput }));
      },
    },

    propertyType: {
      message: "E quanto ao tipo de imóvel que você está procurando?",
      options: ["Casa", "Apartamento"],
      chatDisabled: true,
      path: "propertyStatus",
      function: (params) =>
        setForm((form) => ({
          ...form,
          propertyType: params.userInput,
        })),
    },

    propertyStatus: {
      message:
        "O imóvel que você busca precisa estar pronto pra morar ou em construção?",
      options: ["Pronto para morar", "Em construção", "Tanto faz"],
      chatDisabled: true,
      path: "numberBedrooms",
      function: (params) =>
        setForm((form) => ({
          ...form,
          propertyStatus: params.userInput,
        })),
    },

    numberBedrooms: {
      message: "Quantos dormitórios você precisa?",
      options: ["1", "2", "3+", "Indiferente"],
      chatDisabled: true,
      path: "searchProperty",
      function: (params) =>
        setForm((form) => ({
          ...form,
          numberBedrooms: params.userInput,
        })),
    },

    searchProperty: {
      message: `Legal! ${name} já identifiquei alguns imóveis interessantes pra você!`,
      path: "entryPrice",
      transition: { duration: 1000 },
    },

    entryPrice: {
      message:
        "Para encontrarmos o imóvel certo que cabe no seu bolso, você tem algum valor para a entrada? Quanto?",
      options: [
        "R$ 500,00",
        "R$ 1.000,00",
        "R$ 5.000,00",
        "R$ 10.000,00",
        "R$ 20.000,00",
        "R$ 30.000,00 +",
      ],
      chatDisabled: true,
      path: "income",
      function: (params) => setForm({ ...form, entryPrice: params.userInput }),
    },

    income: {
      message: "Me diga qual sua faixa de renda familiar?",
      options: [
        "R$ 500,00",
        "R$ 1.000,00",
        "R$ 1.500,00",
        "R$ 2.000,00",
        "R$ 2.500,00",
        "R$ 3.000,00",
        "R$ 3.500,00",
        "R$ 4.000,00",
        "R$ 4.500,00",
        "R$ 5.000,00",
        "R$ 5.500,00",
        "R$ 6.000,00",
        "R$ 6.500,00",
        "R$ 7.000,00",
        "R$ 7.500,00",
        "R$ 8.000,00",
        "R$ 8.000,00 +",
      ],
      path: "installmentPrice",
      function: (params) => setForm({ ...form, income: params.userInput }),
    },

    installmentPrice: {
      message: "E qual o valor de parcela que você pode pagar por mês?",
      options: [
        "R$ 500,00",
        "R$ 700,00",
        "R$ 900,00",
        "R$ 1.000,00",
        "R$ 1.200,00 +",
      ],
      chatDisabled: true,
      path: "credit",
      function: (params) =>
        setForm({ ...form, installmentPrice: params.userInput }),
    },

    credit: {
      message:
        "Muito bom! Que tal termos uma ideia de pré-aprovação de crédito pra você?",
      options: ["Quero pré-aprovar", "Não preciso de crédito"],
      function: (params) => {
        if (params.userInput === "Quero pré-aprovar") {
          params.goToPath("cpf");
        } else {
          params.goToPath("phone");
        }
      },
      chatDisabled: true,
    },

    cpf: {
      message: "Legal. Pode nos fornecer seu CPF por favor?",
      options: ["Quero fornecer meu CPF", "Não quero fornecer meu CPF "],
      function: (params) => {
        if (params.userInput === "Quero fornecer meu CPF") {
          params.goToPath("informCPF");
        } else {
          params.goToPath("phone");
        }
      },
    },

    informCPF: {
      message: "Digite seu CPF",
      path: "phone",
      function: (params) => setForm({ ...form, cpf: params.userInput }),
      validateInput: (userInput) => {
        const cpf = userInput.replace(/\D/g, "");

        if (cpf.length !== 11) {
          return {
            success: false,
            promptContent: "CPF incompleto",
            promptDuration: 1000,
            promptType: "error",
            highlightTextArea: true,
          };
        }

        if (typeof cpf === "string" && !Number.isNaN(Number(cpf))) {
          return { success: true };
        }
      },
    },

    phone: {
      message:
        "Agora, para oferecermos as melhores opções, precisamos de algumas informações pessoais. Pode me fornecer seu telefone celular com DDD?",
      path: "email",
      function: (params) => setForm({ ...form, phone: params.userInput }),
      validateInput: (userInput) => {
        const phone = userInput.replace(/\D/g, "");

        if (phone.length !== 11) {
          return {
            success: false,
            promptContent: "Telefone incompleto",
            promptDuration: 1000,
            promptType: "error",
            highlightTextArea: true,
          };
        }

        if (typeof phone === "string" && !Number.isNaN(Number(phone))) {
          return { success: true };
        }
      },
    },

    email: {
      message:
        "Agora precisarei que me informe o seu melhor e-mail, por gentileza",
      function: (params) => {
        setForm({ ...form, email: params.userInput });
        if (form.state !== "SP") {
          params.goToPath("redirect");
        } else {
          params.goToPath("data");
        }
      },
    },

    data: {
      message: "Antes de prosseguirmos, confirme se os dados estão corretos",
      component: (
        <Data>
          <p>
            <strong>Nome:</strong> {name}
          </p>
          <p>
            <strong>Região:</strong> {form.neighborhood}
          </p>
          <p>
            <strong>Parcela: </strong>
            {form.installmentPrice}
          </p>
        </Data>
      ),
      transition: { duration: 1000 },
      path: "renderResults",
    },

    renderResults: {
      message:
        "Ótimo! Vou agora procurar o imóvel perfeito pra você. Isso pode levar alguns instantes.",
      chatDisabled: true,
      transition: { duration: 1000 },

      function: async (params) => {
        const response = await handleProperty();
        if (response) {
          params.goToPath("properties");
          return;
        }

        params.goToPath("noResults");
      },
    },

    noResults: {
      message: "Não encontrei nenhum imóvel",
      transition: { duration: 3000 },
      path: "redirect",
    },
    properties: {
      message: "Estes são os imóveis encontrados: ",
      component: (
        <div>
          {properties.map((property) => {
            const {
              id,
              name,
              default_image,
              address,
              min_area,
              min_parking,
              min_bedrooms,
              min_price,
            } = property;

            return (
              <RenderCards>
                <Card
                  key={id}
                  name={name}
                  image={default_image["520x280"]}
                  dormitory={min_bedrooms}
                  location={address.area}
                  parkingSpaces={min_parking}
                  price={min_price.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })}
                  squareMeters={min_area}
                  suite={0}
                  card="default"
                  onClick={() =>
                    window.open(`https://urblar.com.br/imovel/${id}`)
                  }
                />
              </RenderCards>
            );
          })}
        </div>
      ),
      function: async () => onSubmit(),
      transition: { duration: 3000 },
    },

    redirect: {
      message: "Estou te encaminhando para um consultor",
      chatDisabled: true,
      transition: { duration: 3000 },
      function: async () => await onSubmit(),
    },
  };

  return (
    <Container>
      <Content>
        <Header>
          <img src={Logo} alt="logo" />

          <div onClick={onClose}>
            <h3>Fechar</h3>
            <Icon src={Close} />
          </div>
        </Header>

        <Title>Encontre aqui o seu imóvel dos sonhos</Title>

        <RenderChat>
          <figure>
            <img src={Dog} alt="imagem" />
          </figure>
          <ChatBot
            flow={flow}
            settings={{
              emoji: true,
              fileAttachment: true,
              general: {
                embedded: true,
                primaryColor: "#77b011",
                secondaryColor: "#ff4900",
              },
              header: {
                avatar: IconUrblar,
                title: "Urblar",
              },
              footer: {
                text: "",
              },
              chatInput: {
                enabledPlaceholderText: "",
              },
              notification: {
                disabled: true,
              },
              userBubble: {
                showAvatar: true,
              },
              botBubble: {
                showAvatar: true,
                avatar: IconDog,
              },
              chatHistory: {
                disabled: true,
              },
            }}
            plugins={plugins}
          />
        </RenderChat>
      </Content>
    </Container>
  );
};

export default Chat;
