import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 20px;
  background-color: #f8f8f8;
  min-height: 700px;
  align-items: center;
`;

export const Content = styled.div`
  max-width: 1128px;
  width: 100%;
  height: 100%;
`;

export const ContainerCards = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;

  @media (max-width: 768px) {
    flex-direction: column;

    align-items: center;
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`;
